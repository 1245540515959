import {SEO} from 'components/SEO';
import {graphql} from 'gatsby';
import {useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';

const NotFound: React.FC<{}> = () => {
  const {t} = useI18next();
  const title = t('Page not found!');
  return (
    <div className="relative pt-52">
      <SEO title={title} />
      <div className="container px-6 py-7 md:py-14 relative z-10 min-h-screen flex justify-center">
        <h1 className="text-center">{title}</h1>
      </div>
    </div>
  );
};

export default NotFound;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
